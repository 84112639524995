function toggleLoginForms() {
    const login = document.getElementById('login');
    const register = document.getElementById('register');
    const toggles = document.querySelectorAll('.toggle-forms');
    if (!login || !register) {
        return;
    }
    toggles.forEach((toggle) => {
        toggle.addEventListener('click', () => {
            login.classList.toggle('hidden');
            register.classList.toggle('hidden');
        });
    });
}
export default function initWoocommerce() {
    toggleLoginForms();
}
