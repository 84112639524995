export default function initMainNav() {
    const mainNav = document.querySelector('.nav-primary');
    const parentMenus = mainNav.querySelectorAll('.has-children');
    parentMenus.forEach((menu) => {
        const childMenu = menu.querySelector('.child-menu');
        childMenu.style.position = 'absolute';
        childMenu.style.visibility = 'hidden';
        childMenu.style.width = 'auto';
        const menuWidth = childMenu.clientWidth + 16;
        childMenu.style.width = '0';
        childMenu.style.position = '';
        childMenu.style.visibility = '';
        menu.addEventListener('mouseover', function () {
            childMenu.style.width = `${menuWidth}px`;
        });
        menu.addEventListener('mouseout', function () {
            childMenu.style.width = '0';
        });
    });
}
