import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
export default function initCarousels() {
    const carousels = document.querySelectorAll('.swiper');
    let swiperOptions = {
        modules: [Autoplay, Pagination],
        slidesPerView: 1,
        spaceBetween: 32,
        loop: true,
        autoplay: {
            delay: 3000,
        },
        pagination: {
            dynamicBullets: true
        },
        breakpoints: {
            768: {
                slidesPerView: 3
            }
        }
    };
    carousels.forEach((carousel) => {
        if (carousel.dataset.slidesPerView) {
            swiperOptions.breakpoints["768"].slidesPerView = parseInt(carousel.dataset.slidesPerView);
        }
        new Swiper(carousel, swiperOptions);
    });
}
