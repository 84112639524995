import domReady from '@roots/sage/client/dom-ready';
import './components/fontAwesome';
import initCarousels from "@scripts/components/swiper";
import initFaqs from "@scripts/components/faq";
import initSidebar from "@scripts/components/sidebar";
import initMainNav from "@scripts/components/mainNav";
import initWoocommerce from "@scripts/components/woocommerce";
import initTypeform from "@scripts/components/typeform";
/**
 * Application entrypoint
 */
domReady(async () => {
    initMainNav();
    initSidebar();
    initCarousels();
    initFaqs();
    initWoocommerce();
    initTypeform();
});
/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot)
    import.meta.webpackHot.accept(console.error);
