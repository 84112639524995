export default function initSidebar() {
    const sidebar = document.getElementById('sidebar');
    const sidebarToggles = document.querySelectorAll('.toggle-sidebar');
    const submenuToggles = sidebar.querySelectorAll('.toggle-submenu');
    sidebarToggles.forEach((toggle) => {
        toggle.addEventListener('click', function () {
            sidebar.dataset.visible = sidebar.dataset.visible === 'true' ? 'false' : 'true';
        });
    });
    submenuToggles.forEach((toggle) => {
        toggle.addEventListener('click', function () {
            const submenu = toggle.parentNode;
            submenu.dataset.visible = submenu.dataset.visible === 'true' ? 'false' : 'true';
        });
    });
}
