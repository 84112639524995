export default function initTypeform() {
    window.addEventListener('message', function (e) {
        if (e.data?.type === 'embed-auto-close-popup') {
            const activePopups = document.querySelectorAll('.pum-active');
            activePopups.forEach((popup) => {
                // @ts-ignore
                const p = PUM.getPopup(popup);
                p.trigger('pumSetCookie');
                p.popmake('close');
            });
        }
    });
}
