function toggleOpen(e, defaultHeight) {
    if (e.style.height === '0px') {
        e.style.height = defaultHeight;
    }
    else {
        e.style.height = '0px';
    }
}
export default function initFaqs() {
    const faqs = document.querySelectorAll('.faq');
    faqs.forEach((faq) => {
        const question = faq.querySelector('.question');
        const answer = faq.querySelector('.answer');
        const answerHeight = `${answer.getBoundingClientRect().height}px`;
        answer.style.height = '0px';
        question.addEventListener('click', function () {
            faq.dataset.visible = faq.dataset.visible === 'true' ? 'false' : 'true';
            toggleOpen(answer, answerHeight);
        });
    });
}
